import { BaseApi, ggFormApi, apiUpload } from "./base-api";
import req from "../fetch.js";

const apiDevLocal = 'http://localhost:1337/v1/'
const headers = { "Content-Type": "text/plain;charset=UTF-8" }
export default {
    callToAction: {
        campaign(body, cb) {
            req.post(BaseApi + 'call-to-action/campaign/index', headers, body, (e, r) => {
                cb ? cb(e, r) : false
            })
        },
        template(body, cb) {
            req.post(BaseApi + 'call-to-action/template/index', headers, body, (e, r) => {
                cb ? cb(e, r) : false
            })
        },
        googleForm(body, cb) {
            req.post(ggFormApi, headers, body, (e, r) => {
                cb ? cb(e, r) : false
            })
        },
        // uploadImage(body, cb) {
        //     req.post(BaseApi, body, (e, r) => {
        //         cb ? cb(e, r) : false
        //     })
        // },
        createAsset(body, cb) {
            req.postImage(BaseApi + 'selling-page/other/create_asset', {}, body, (e, r) => {
                cb ? cb(e, r) : false
            })
        },
        getAsset(body, cb) {
            req.post(BaseApi + 'selling-page/other/get_asset_user', headers, body, (e, r) => {
                cb ? cb(e, r) : false
            })
        },
        removeAsset(body, cb) {
            req.post(BaseApi + 'selling-page/other/remove_asset', headers, body, (e, r) => {
                cb ? cb(e, r) : false
            })
        },

    }
}