export default {
    confirm: (vue, mess, duartion, callback, callback2) => {
        vue.$toasted.show(mess, {
            theme: "toasted-primary", 
            position: "top-center", 
            duration : duartion,
            action: [
               {
                  text: 'Hủy',
                  onClick: (e, toastObject) => {
                     toastObject.goAway(0);
                     if (callback2) callback2()
                  }
               },
               {
                  text: 'Đồng ý',
                  onClick: (e, toastObject) => {
                     toastObject.goAway(0)
                     if (callback) callback()                     
                  }
               }
            ]
         })        
    },
    success: (vue, mess, duration ) => {
        vue.$toasted.show(mess, { 
            theme: "bubble", 
            position: "top-center", 
            duration : duration
       })
    },
    fail: (vue, mess, duration) => {
        vue.$toasted.error(mess, {
            duration: duration,
            position: "top-right"
        })
    },
}