
export default {
    setup: function() {
        $(document).on('mouseenter', '.tabs-b .item', function(evt) {
            //do something
            $(this).addClass('tabs-hover')

            $(this).one('mouseleave', function (evt) {
                $(this).removeClass('tabs-hover')
            })
        })

        $(document).on('click', '.tabs-b .item', function (evt) {
            $('.tabs-b .item').each(function () {
                $(this).removeClass('tabs-active')
            })
            $(this).toggleClass('tabs-active')
        })

    }
}