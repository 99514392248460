<template>
  <div id="app">
    <!-- <loading
		:active.sync="$store.state.$loading"
		:can-cancel="false"
		:is-full-page="true"
		loader="dots"
		color="#f87675">
    </loading> -->

    <loadingCircle :active="$store.state.$loading"></loadingCircle>
    <modalUpload v-if="$store.state.modal.isShowUpload" />
    <modalCodeHTML v-if="$store.state.modal.isShowCodeHTML" />
    <router-view />
  </div>
</template>

<script>
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";

import loadingCircle from "./components/component-share/layout/loading-circle/loading.vue";
import modalUpload from "./components/call-to-action/modal/modal-upload/modal-upload.vue";
import modalCodeHTML from "./components/call-to-action/modal/modal-code-html/modal-code-html.vue";

export default {
  components: {
    loadingCircle,
    modalUpload,
    modalCodeHTML,
  },
};
</script>

<style lang="scss">
</style>
