import moment from 'moment'

export default {
    formatVND(input) {
        input = Number(input)
        return input.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})
    },
    formatTime(input) {
        input = Number(input)
        if (input) {
            return moment(input).format('MM/DD/YYYY hh:mm:ss')
         }
    }
}