import $service from '@/services/api/api.js'
import { apiUpload } from '@/services/api/base-api.js'
import $alert from '@/services/alert.js'

export default {
    props: [],
    data() {
        return {
            isShow: false,
            userId: '',
            listImage: [],
            skip: 0,
            limit: 20,
        }
    },
    created() {
        this.isShow = this.$store.state.modal.isShowUpload
        this.userId = this.$store.state.callToAction.userId
    },
    mounted() {
        console.log('mounted modal upload', this.$store.state.modal.isShowUpload);
        this.handleUploadImage('read-all')
    },
    computed: {},
    methods: {
        handleHideModalUpload() {
            this.$store.commit('hideModalUpload')
        },
        handleUploadImage(type, idImage) {
            this.$store.state.$loading = true
            let file = ''
            let body = ''
            switch (type) {
                case 'create':
                    file = this.$refs.inputUploadImage.files[0]
                    body = new FormData();
                    body.append("user_id", this.userId)
                    body.append("file", file)

                    $service.callToAction.createAsset(body, (e, r) => {
                        this.$store.state.$loading = false
                        if (r && r.data) {
                            this.listImage.unshift(r.data)
                        }
                    })

                    break;
                case 'read-all':
                    body = {
                        user_id: this.userId,
                        skip: this.skip,
                        limit: this.limit,
                        sort: 'createdAt DESC'
                    }

                    $service.callToAction.getAsset(body, (e, r) => {
                        this.$store.state.$loading = false
                        if (r && r.data) {
                            this.listImage = this.listImage.concat(r.data)
                            if (r.data.length == 0) {
                                $alert.success(this, 'Đã hiển thị hết ảnh', 3000)

                            }
                        }
                    })

                    break;
                case 'read-one':
                    $service.callToAction.getAsset(body, (e, r) => {
                        this.$store.state.$loading = false
                        if (r) {

                        }
                    })
                    break;
                case 'delete':
                    body = { id: idImage }

                    $service.callToAction.removeAsset(body, (e, r) => {
                        this.$store.state.$loading = false
                        if (r && r.data) {
                            this.listImage = this.listImage.filter((image, indexImage) => {
                                if (image.id !== idImage) return image
                            })
                        }
                    })
                    break;
                default:
                    break;
            }
        },
        changeLimitIndex(typeLimit) {
            if (typeLimit === 'more') {
                this.skip += 20
                // this.limit += 20
                return
            }
            if (typeLimit === 'reset') {

            }
        },
        handleGetMoreImage() {
            this.changeLimitIndex('more')
            this.handleUploadImage('read-all')
        },
        toggleUseImage(path) {
            let url = 'https://api-cms.botup.io' + path
            this.$store.commit('setUrlImage', 'none')
            this.$store.commit('setUrlImage', url)
            this.handleHideModalUpload()
        },
        toggleDeleteImage(id) {
            $alert.confirm(
                this,
                'Bạn muốn xoá ảnh này?',
                null,
                () => {
                    this.handleUploadImage('delete', id)
                },
                () => {

                }
            )
        }

    },
    watch: {
        '$store.state.modal.isShowUpload': {
            deep: true,
            handler(newVal) {
                if (newVal) {
                    return this.isShow = true
                }
                this.isShow = false
            }
        }
    }
}