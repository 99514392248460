function isOutside(selector, evt) {
    return !evt.target.matches(selector) && !evt.target.matches(`${selector} *`)
}

function closeMenu(eleJquery) {
    eleJquery.removeClass(`active`)
    eleJquery.removeClass(`drodwn-top`)
    eleJquery.removeClass(`drodwn-right`)
    eleJquery.removeClass(`drodwn-left`)
    eleJquery.removeClass(`drodwn-bottom`)
}

export default {
    setup: function () {
        $(document).on('click', '.drodwn .drodwn-trigger', function () {
            const trigger = $(this)
            var position = trigger.data('position')
            const menu = trigger.closest('.drodwn').children('.drodwn-menu')

            switch (position) {
                case 'top':
                    position = 'drodwn-top'
                    break
                case 'right':
                    position = 'drodwn-right'
                    break
                case 'left':
                    position = 'drodwn-left'
                    break
                default:
                    position = 'drodwn-bottom'
            }

            $('.drodwn .drodwn-menu').each(function (evt) {
                const m = $(this)
                if (!m.is(menu)) {
                    closeMenu(m)
                }
            })

            menu.toggleClass(`active ${position}`)

            // just check one time for optimize
            function checkOutsideOfDropdown (evt) {
                if (isOutside('.drodwn .drodwn-trigger', evt) && isOutside('.drodwn .drodwn-menu', evt)) {
                    
                    menu.removeClass(`active ${position}`)

                    $(document).off( "click", "body", checkOutsideOfDropdown )
                }
            }
            $(document).on( "click", "body", checkOutsideOfDropdown )
 
        })


    },
}