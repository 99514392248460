import Vue from "vue"
import VueRouter from "vue-router"
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
    // "/"
    {
        path: "/",
        name: "dashboard",
        redirect: { name: 'campaigns' },
        component: () => import(/* webpackChunkName: "Dashboard" */ "@/views/dashboard/dashboard.vue"),
        children: [
            // call to action
            {
                path: 'call-to-action',
                name: 'call-to-action',
                props: true,
                component: () => import('@/components/call-to-action/Index.vue'),
                children: [
                    {
                        path: 'campaigns',
                        name: 'campaigns',
                        props: true,
                        component: () => import('@/components/call-to-action/campaigns/Campaigns.vue'),
                    },
                    {
                        path: 'new',
                        name: 'new',
                        props: true,
                        component: () => import(/* webpackChunkName: "Newcampaign" */ '@/components/call-to-action/new-campaign/New-campaign.vue'),
                    },
                    {
                        path: 'edit',
                        name: 'edit',
                        props: true,
                        component: () => import(/* webpackChunkName: "Editcampaign" */ '@/components/call-to-action/edit-campaign/Edit-campaign.vue'),
                    },
                    {
                        path: 'preview',
                        name: 'preview',
                        props: true,
                        component: () => import('@/components/call-to-action/edit-campaign/preview/preview.vue'),
                    }
                ]
            },

        ]
    },

    // "/login"
    {
        path: "/login",
        name: "Login",
        component: () => import(/* webpackChunkName: "Login" */ "./views/login/login.vue"),
    },
    {
        path: "*",
        redirect: { name: 'campaigns' },
    },

]

const router = new VueRouter({
    routes
})

export default router
