import { render, staticRenderFns } from "./modal-code-html.html?vue&type=template&id=98143a9a&scoped=true&"
import script from "./modal-code-html.js?vue&type=script&lang=js&"
export * from "./modal-code-html.js?vue&type=script&lang=js&"
import style0 from "./modal-code-html.vue?vue&type=style&index=0&id=98143a9a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98143a9a",
  null
  
)

export default component.exports