var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"bbh-cms-cta-modal-code-html"}},[_c('div',{staticClass:"modal",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.handleHideModalCodeHTML()}}},[_c('div',{staticClass:"modal-msg"},[_c('div',[_c('div',{staticClass:"close",on:{"click":function($event){return _vm.handleHideModalCodeHTML()}}},[_c('img',{attrs:{"src":require("@/assets/image/call-to-action/icon/close.svg"),"alt":""}})])]),_c('div',{staticClass:"modal-edit-template-content"},[_vm._m(0),_c('div',{staticClass:"modal-content-code-html margin-bottom-12"},[_c('AceEditor',{attrs:{"lang":"html","theme":"chrome","width":"100%","height":"200px","options":{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        fontSize: 14,
                        highlightActiveLine: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2,
                        showPrintMargin: false,
                        showGutter: true,
                    },"commands":[
                        {
                            name: 'save',
                            bindKey: { win: 'Ctrl-s', mac: 'Command-s' },
                            exec: _vm.dataSumit,
                            readOnly: true,
                        } ]},on:{"init":_vm.editorInit},model:{value:(_vm.innerHTML),callback:function ($$v) {_vm.innerHTML=$$v},expression:"innerHTML"}})],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"text-medium-weight-normal"},[_vm._v(" "+_vm._s(_vm.textTitle)+" ")]),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('button',{staticClass:"button-outline mr-2",on:{"click":function($event){return _vm.handleHideModalCodeHTML()}}},[_vm._v("Huỷ")]),_c('button',{staticClass:"button-primary",on:{"click":function($event){return _vm.toggleButtonSave()}}},[_vm._v("Lưu")])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-content-title"},[_c('div',{staticClass:"text-large-weight-bold"},[_vm._v(" HTML code ")])])}]

export { render, staticRenderFns }