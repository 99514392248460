let _$http = {
    get: function (uri, cb) {
        fetch(uri, {
            method: "GET",
            headers: {
                "Content-Type": "text/plain;charset=UTF-8"
            },
        })
            .then(r => r.json())
            .then(function (r) {
                console.log('rrrrrr', r);
                if (r) cb(null, r);
            })
            .catch(function (e) {
                console.log("Error ::: ", e);
                cb(e)
            })
    },
    post: function (uri, headers, data, cb) {
        fetch(uri, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        })
            .then(r => r.json())
            .then(function (r) {
                if (r) cb(null, r);

            })
            .catch(function (e) {
                console.log("Error ::: ", e);
                cb(e)
            })
    },
    postImage: function (uri, headers, data, cb) {
        fetch(uri, {
            method: 'POST',
            headers: headers,
            body: data,
        })
            .then(r => r.json())
            .then(function (r) {
                if (r) cb(null, r);

            })
            .catch(function (e) {
                console.log("Error ::: ", e);
                cb(e)
            })
    },
}


export default _$http;