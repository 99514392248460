export default {
    showModalUpload: (state, payload) => {
        state.type = payload
        state.isShowUpload = true
        console.log('mutations showModalUpload');
    },
    showModalCodeHTML: (state, payload) => {
        state.type = payload
        state.isShowCodeHTML = true
        console.log('mutations showModalCodeHtml');
    },
    hideModalUpload: (state) => {
        state.isShowUpload = false
        console.log('mutations hideModalUpload');
    },
    hideModalCodeHTML: (state) => {
        state.isShowCodeHTML = false
        console.log('mutations hideModalCodeHtml');
    },
    setUrlImage: (state, payload) => {
        state.urlImage = payload
        console.log('mutations useImageUrl', state.urlImage);
    },
    setCodeHTML: (state, payload) => {
        state.codeHTML = payload
        console.log('mutations codeHtml', state.codeHTML);
    },
}