import $service from '@/services/api/api.js'
import { apiUpload } from '@/services/api/base-api.js'
import $alert from '@/services/alert.js'
import AceEditor from 'vue2-ace-editor';
export default {
    components: {
        AceEditor
    },
    props: [],
    data() {
        return {
            innerHTML: "",
            textTitle: 'Bất kỳ thẻ <style> và </script> sẽ ảnh hưởng đến chiến dịch'
        }
    },
    created() {
    },
    mounted() {
        this.innerHTML = this.$store.state.modal.codeHTML
    },
    computed: {},
    methods: {
        handleHideModalCodeHTML() {
            this.$store.commit('hideModalCodeHTML')
        },
        toggleButtonSave() {
            this.setCodeHTMLToStore()
            this.handleHideModalCodeHTML()
        },

        dataSumit() {

        },
        editorInit() {
            require('brace/ext/language_tools') //language extension prerequsite...
            require('brace/mode/html')
            require('brace/mode/javascript')    //language
            require('brace/mode/less')
            require('brace/theme/chrome')
            require('brace/snippets/javascript') //snippet
            require('brace/snippets/html') //snippet
        },
        setCodeHTMLToStore() {
            this.$store.commit('setCodeHTML', this.innerHTML)
            console.log('this.$store.state.callToAction.modal.codeHTML', this.$store.state.modal.codeHTML);
        }
    },
    watch: {

    }
}