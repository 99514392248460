// const BaseApi = 'http://localhost:1337/v1/'   // * local
// const BaseApi = 'https://devbbh.tk/v1/'         // * dev
const BaseApi = 'https://api-cms.botup.io/v1/'         // * deproductv

// contst apiUpload = "https://devbbh.tk/";     // * dev
const apiUpload = "https://api-cms.botup.io/";     // * production
// const CtaCoreApi = 'http://localhost:1337/js/bbh.js'   // * local
// const CtaCoreApi = 'https://devbbh.tk/js/bbh.js'          // * dev
const CtaCoreApi = 'https://api-cms.botup.io/js/bbh.js'          // * product

const ggFormApi = 'https://chatbox-widget.botbanhang.vn/v1/widget-order-ggform/GGForm/get-html' // * product
export { BaseApi, CtaCoreApi, ggFormApi, apiUpload }