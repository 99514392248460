import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
    state: {
        isShowUpload: false,
        isShowCodeHTML: false,
        type: "",   // * check when watch urlImage
        urlImage: '',    // * url image use upload
        codeHTML: '',    // * code html in blockHtml

    },
    getters,
    mutations,
    actions
};
